import { StaticImage } from 'gatsby-plugin-image';
import { ExternalLink } from 'components/Links';

export const useEventsContent = () => [
  {
    header: 'Global investing opportunities for Aussie investors',
    textContent: (
      <p>
        Join us as Prashant Mohan (CMO at Sharesight) and Ricky Yeo (Customer Support Team Manager
        at Sharesight) delve into how Aussie investors can broaden their portfolios by tapping into
        international markets.
        <br /> <em>Webinar happening on 27 August 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://us02web.zoom.us/webinar/register/WN_vbEDtdPcS8qjyAbNrM4PXA?utm_source=webinar&utm_medium=social#/registration"
        title="Global investing opportunities for Aussie investors"
        asButton
        appearance="secondary"
      >
        Register
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/global-investing.png"
        alt="Global investing opportunities for Aussie investors"
      />
    ),
  },
  {
    header: 'How to build a balanced bond portfolio',
    textContent: (
      <p>
        Discover how to navigate the vital role of bonds in a diversified portfolio and gain the
        tools needed to create a well-balanced bond portfolio.
        <br /> <em>Webinar recording from 14 Aug 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=cJ7u6keZuyI"
        title="How to build a balanced bond portfolio"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/build-balanced-portfolio.png"
        alt="How to build a balanced bond portfolio"
      />
    ),
  },
  {
    header: 'Portfolio construction',
    textContent: (
      <p>
        In the final investing masterclass webinar with Vince Scully of Life Sherpa, we cover key
        steps for building a portfolio that matches your goals, timeframe, and risk tolerance.
        <br /> <em>Webinar recording from 16 May 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=DnlJe1lu9YE"
        title="Portfolio construction"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/portfolio-construction.png"
        alt="Portfolio Construction"
      />
    ),
  },
  {
    header: 'Beyond the core',
    textContent: (
      <p>
        In our fourth investing masterclass webinar with Vince Scully of Life Sherpa, we explore
        thematic investing strategies.
        <br /> <em>Webinar recording from 16 April 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=3bo3BfTBMLs"
        title="Beyond the core"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/beyond-the-core.png"
        alt="Beyond the core"
      />
    ),
  },
  {
    header: 'Secrets to successful tech and AI investing',
    textContent: (
      <p>
        Explore key investment tools for success in the tech and AI sectors with Mark Moreland,
        co-founder of Teaminvest.
        <br /> <em>Webinar recording from 19 March 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=ruT2zOz3tOc"
        title="Secrets to successful tech and AI investing"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/ai-investing.png"
        alt="Secrets to successful tech and AI investing"
      />
    ),
  },
  {
    header: 'Deep dive into defensive assets',
    textContent: (
      <p>
        In our third investing masterclass webinar with Vince Scully of Life Sherpa, we discuss
        investing in defensive assets.
        <br /> <em>Webinar recording from 14 March 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=xGDvnEzfnxA"
        title="Deep dive into defensive assets"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/defensive-assets.png"
        alt="Deep dive into defensive assets"
      />
    ),
  },
  {
    header: "Women in Investing - Shani's story",
    textContent: (
      <p>
        Explore Shani's investing journey in our 'Women in Investing' campaign, in celebration of
        International Women’s Day 2024.
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=FbggZpxxWnM"
        title="Women in Investing - Shani's story"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/shani.png"
        alt="Women in Investing - Shani's story"
      />
    ),
  },
  {
    header: "Women in Investing - Hannah's story",
    textContent: (
      <p>
        Explore Hannah's investing journey in our 'Women in Investing' campaign, in celebration of
        International Women’s Day 2024.
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=_y1EtNKSnNw"
        title="Women in Investing - Hannah's story"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/hannah.png"
        alt="Women in Investing - Hannah's story"
      />
    ),
  },
  {
    header: "Women in Investing - Angela's story",
    textContent: (
      <p>
        Explore Angela's investing journey in our 'Women in Investing' campaign, in celebration of
        International Women’s Day 2024.
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=VVLVMLdm9qY"
        title="Women in Investing - Angela's story"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/angela.png"
        alt="Women in Investing - Angela's story"
      />
    ),
  },
  {
    header: 'Deep dive into growth assets',
    textContent: (
      <p>
        In our second investing masterclass webinar with Vince Scully of Life Sherpa, we explore the
        strategy of investing in growth assets through ETFs.
        <br /> <em>Webinar recording from 8 February 2024.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=oCKaj97iokI"
        title="Deep dive into growth assets"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/growth-assets.png"
        alt="Deep dive into growth assets"
      />
    ),
  },
  {
    header: 'Optimising your portfolio',
    textContent: (
      <p>
        Explore investing fundamentals in our first masterclass webinar with Vince Scully, Founder
        of Life Sherpa.
        <br /> <em>Webinar recording from 12 December 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=Naa2cLTG-LI"
        title="Optimising your portfolio"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/optimising.png"
        alt="Optimising your portfolio"
      />
    ),
  },
  {
    header: 'Key insights from the 2023 ASX Investor study',
    textContent: (
      <p>
        Join Rory Cunningham, Senior Manager of Investment Products from the ASX, on the latest
        trends in Australian investments.
        <br />
        <em>Webinar recording from 20 September 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=-2DxXbygpqY"
        title="Key insights from the 2023 ASX Investor study event"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/2023-asx-investor-study.png"
        alt="Key insights from the ASX Investor Study banner"
      />
    ),
  },
  {
    header: 'Sharesight Investor-tech Conference 2023 risk panel discussion',
    textContent: (
      <p>
        The risk panel discussion covers the topic of how investors should think about their
        portfolio risk and how they can prepare for it.
        <br /> <em>Panel discussion from 23 August 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=bF8nZ0GLQ8o"
        title="Sharesight Investor-tech Conference 2023 risk panel discussion"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/investor-tech.png"
        alt="Sharesight Investor-tech Conference 2023 risk panel discussion"
      />
    ),
  },

  {
    header: 'How to use Sharesight at tax time in NZ',
    textContent: (
      <p>
        Our Head of Product, Ben Clendon, covers several topics in this video that are relevant to
        NZ investors. One of the essential topics he discusses is how to generate tax reports,
        specifically the FIF report, for tax purposes. <br />
        <em>Webinar recording from 28 June 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=7wKyrNxyb20"
        title="Event recording: How to use Sharesight at tax time in NZ"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/tax-time.png"
        alt="Using Sharesight at tax time banner"
      />
    ),
  },
  {
    header: 'Tax planning for Australian investors',
    textContent: (
      <p>
        Umair Minhas, Direct of Tax at PwC, takes us through various tax planning strategies and
        answers a lot of questions. <br />
        <em>Webinar recording from 07 June 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=efap9eaavmE"
        title="Event recording: Tax planning for Australian Investors"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/tax-planning.png"
        alt="Tax planning for Australian investors banner"
      />
    ),
  },
  {
    header: 'Tracking employee stock options on Sharesight',
    textContent: (
      <p>
        Doug Morris takes us through how best to keep track of various flavours of stock options on
        Sharesight. <br />
        <em>Webinar recording from 24 May 2023.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=uGgWez28qnU"
        title="Event recording: Tracking employee stock options on Sharesight"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/stock-options.png"
        alt="Tracking employee stock options on Sharesight banner"
      />
    ),
  },

  {
    header: 'Adviser Innovation Summit 2021',
    textContent: (
      <p>
        Sharesight’s Chris Stojcevski uncovers how Saas can improve your insights and reduce costs
        for your clients. <br />
        <em>Event recording from 9 November 2021.</em>
      </p>
    ),
    button: (
      <ExternalLink
        to="https://www.youtube.com/watch?v=whZJiqO1BQc"
        title="Event recording: Adviser Innovation Summit 2021"
        asButton
        appearance="secondary"
      >
        Watch
      </ExternalLink>
    ),
    image: (
      <StaticImage
        width={335}
        height={190}
        src="../images/events/ais-2021.png"
        alt="Adviser Innovation Summit 2021"
      />
    ),
  },
];
