const SVGHero = () => (
  <svg
    width="412"
    height="276"
    viewBox="0 0 412 276"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of three cones</title>
    <path d="M191.908 147.043L62.3625 143.39L120.129 246.456L191.908 147.043Z" fill="#A9B0EF" />
    <path d="M191.891 147.174L300.467 257.493L349.28 143.953L191.891 147.174Z" fill="#A9B0EF" />
    <path d="M192.025 147.04L283.87 130.507L202.83 51.9619L192.025 147.04Z" fill="#A9B0EF" />
    <circle cx="345.651" cy="209.837" r="65.8177" fill="#202EAE" />
    <circle cx="66.0269" cy="209.837" r="65.8177" fill="#202EAE" />
    <circle cx="267.155" cy="65.8314" r="65.8177" fill="#202EAE" />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
